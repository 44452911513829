<template>
    <div class="broadBandDetails">
        <van-nav-bar class="navbar" title="查看详情" fixed left-arrow @click-left="leftReturn"/>
        <div class="applyText">
            申请详情
        </div>
        <div class="broadBandDetails-Apply">
            <div class="address">
                <h3 class="addressH3">{{applyDetails.address}}</h3>
            </div>
            <div class="repairee">
                <div class="title">报修人：</div>
                <div class="content" id="contentId">{{applyDetails.Repairee}}</div>
            </div>
            <div class="contactInformation">
                <div class="title">联系方式：</div>
                <div class="content">{{applyDetails.ContactInformation}}</div>
            </div>
            <div class="contactInformation" id="maintainDegree">
                <div class="title">维修程度：</div>
                <div class="content" id="MaintenanceLevel">{{applyDetails.MaintenanceLevel}}</div>
            </div>
            <div class="orderSource">
                <div class="title">订单来源：</div>
                <div class="content">{{applyDetails.OrderSource}}</div>
            </div>
            <div class="orderSource">
                <div class="title">维修类型：</div>
                <div class="content" id="manage">{{applyDetails.DredgeManagement}}</div>
            </div>
            <div class="orderSource" >
                <div class="title">预约时间：</div>
                <div class="content" id="appointment">{{applyDetails.Appointment}}</div>
            </div>
            <div class="orderSource1">

                <div class="content"id="matainDetils"><span class="span1">报修描述：</span>{{applyDetails.MatainDetils}}</div>
            </div>
        </div>
        <div class="dispatchText">
            派单信息
        </div>
        <div class="broadBandDetails-dispatch">
            <div class="address1">

            </div>
            <div class="repairee">
                <div class="title">供应商：</div>
                <div class="content" id="contentId1">{{dispatchDetails.Repairee}}</div>
            </div>
            <div class="contactInformation">
                <div class="title">联系方式：</div>
                <div class="content">{{dispatchDetails.ContactInformation}}</div>
            </div>
            <div class="contactInformation" id="maintainDegree1">
                <div class="title">预计费用：</div>
                <div class="content" id="MaintenanceLevel1">{{dispatchDetails.cost}}</div>
            </div>
            <div class="orderSource">
                <div class="title">品牌：</div>
                <div class="content" id="brandId">{{dispatchDetails.brand}}</div>
            </div>
            <div class="orderSource">
                <div class="title">型号：</div>
                <div class="content" id="modelId">{{dispatchDetails.model}}</div>
            </div>
            <div class="orderSource" >
                <div class="title">派单时间：</div>
                <div class="content" id="appointment1">{{dispatchDetails.dispatchtime}}</div>
            </div>
            <div class="orderSource">
                <div class="title">预计安装时间：</div>
                <div class="content"id="matainDetils1">{{dispatchDetails.hopetime}}</div>
            </div>
        </div>

        <div class="dispatchText">
            完成信息
        </div>
        <div class="potential"  style="margin-bottom: -20px">
            <!-- 图片墙 -->
            <div class="panel withMargin" style="width: 345px;height: 105px">
                <div class="swiper_show" ref="wrapperBox">
                    <div class="content" style="width: 660px;height: 79px">
                        <div class="imgWrap" style="width: 100px;height: 79px" v-for="(url,i) in imagesList" @click="previewImg(i)">
                            <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
                        </div>
                        <div class="num">
                            {{imagesList.length}}图
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import BScroll from 'better-scroll';
    import { NavBar,//标题
        //合同详情、申请详情
        Tab,
        Tabs,
        Row,
        Col,
        DropdownMenu,
        DropdownItem,
        Image as VanImage,
        Uploader,//上传图片参数
        //tab部分
        Popup,
        Field,
        ImagePreview,
        //tab关联部分
        DatetimePicker,
        List,
        Tag,
        //点击弹出部分
        Button,
        Overlay,
        Collapse,
        CollapseItem} from 'vant';
    import {responseUtil} from "../../../libs/rongxunUtil";
    export default {
        components: {
            [NavBar.name]: NavBar,
            // [Tab.name]: Tab,
            // [Tabs.name]: Tabs,
            // [Row.name]: Row,
            // [Col.name]: Col,
            // [DropdownMenu.name]: DropdownMenu,
            // [DropdownItem.name]: DropdownItem,
            [VanImage.name]: VanImage,
            // [Uploader.name]: Uploader,//上传图片参数
            // //tab部分
            // [Popup.name]: Popup,
            // [Field.name]: Field,
            // [ImagePreview.Component.name]: ImagePreview.Component,
            // //tab关联部分
            // [DatetimePicker.name]: DatetimePicker,
            // [List.name]: List,
            // [Tag.name]: Tag,
            //点击弹出部分
            // [Button.name]: Button,
            // [Overlay.name]: Overlay,
            // [Collapse.name]: Collapse,
            // [CollapseItem.name]: CollapseItem
        },

        created() {
            this.created()
        },
        methods:{
            previewImg(start){
                ImagePreview(
                    {
                        images: this.imgList,
                        startPosition: start,
                        onClose() {
                            // do something
                        },
                    }
                )
            },
            leftReturn(){
                this.$router.go(-1);
            },
            created() {
                this.$nextTick(() => {
                    if (!this.scroll) {
                        this.scroll = new BScroll(this.$refs.wrapperBox, {
                            scrollX: true,
                            eventPassthrough: 'vertical'
                        })
                    }
                })
            }

        },
        data(){
            return{
                imagesList: [
                    'https://img.yzcdn.cn/vant/cat.jpeg',
                    'https://img.yzcdn.cn/vant/apple-1.jpg',
                    'https://img.yzcdn.cn/vant/apple-2.jpg',
                    'https://img.yzcdn.cn/vant/cat.jpeg',
                    'https://img.yzcdn.cn/vant/cat.jpeg',
                    'https://img.yzcdn.cn/vant/cat.jpeg',
                ],

                applyDetails:{
                    address:"花和年华一期-职工街100号1单元101号",
                    Repairee:"沉思",
                    ContactInformation:"21222311221",
                    OrderSource:"公司",
                    DredgeManagement:"疏通管理",
                    MaintenanceLevel:"紧急",
                    Appointment:"2020-02-12 13:45",
                    MatainDetils:"这里坏了这里坏了这里坏了这里坏了这里坏了这里坏了这里坏了这"

                },
                dispatchDetails:{
                    Repairee:"沉思",
                    ContactInformation:"21222311221",
                    brand:"联想",
                    cost:"¥45.00",
                    model:"221233321",
                    dispatchtime:"2020-02-12 13:45",
                    hopetime:"2020-02-12 13:45"
                }

            }
        }
    }

</script>

<style scoped lang="less">
    @redius: 8px;
    @border: 1px solid rgb(244, 244, 244);
    .potential {
        width: 375px;
        background-color: #F8F8F8;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: hidden;
        color: #252525;
        position: relative;
        top: -9.5px;

        .sub_btn {
            margin: 30px 0 70px;

            .sub_button {
                border-radius: @redius;
                color: rgb(255, 255, 255);
                border: none;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));

                &.not_choose {
                    background: none;
                    background-color: rgba(184, 184, 184, 0.2);
                }
            }
        }

        p {
            margin: 0px;
        }

        .application_time {
            text-align: right;
            width: 345px;
            font-size: 12px;
            color: rgb(136, 136, 136);
            padding: 2px 0 5px;
        }

        .subtitle {
            padding: 5px 0px;
            color: rgb(244, 99, 76);
            margin-top: 25px;
            font-weight: 700;
            font-size: 12px;
        }
        .overDiv{
            height: 23px;
            /*line-height: 10px;*/
            /*border: 1px solid red;*/
            display: flex;
            align-items: center;
            /*justify-content: center;*/
        }
        /*实际结束更改*/
        .overDiv>div{
            font-weight: bolder;
        }
        .overDiv>span{
            font-size: 13px;
            color: rgb(110,110,110);
        }

        /*.calendar_icon {*/
        /*    display: inline-block;*/
        /*    width: 18px;*/
        /*    height: 18px;*/
        /*    background: url(../../assets/images/calendar.png) no-repeat center center;*/
        /*    background-size: cover;*/
        /*    margin-left: 10px;*/
        /*    background-position-y: -1px;*/
        /*}*/

        .panel {
            width: 345px;
            background-color: rgb(255, 255, 255);
            border-radius: @redius;
            position: relative;
            font-size: 14px;
            text-align: left;
            overflow: hidden;



            &.withMargin {
                margin: 15px 0;
            }

            &>p {
                padding: 15px 15px 0px;
                font-weight: bolder;
                /*color: #252525;*/

            }

            &>p>span {
                font-weight: normal;
                color: rgb(190, 190, 190);
            }

            .state {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 20px;
                font-weight: bold;
                height: 32px;
                line-height: 32px;
                width: 60px;
                display: block;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                border-top-left-radius: 16px;
                border-bottom-right-radius: 16px;
                color: rgb(255, 255, 255);
                text-align: center;
                transform: scale(0.5);
                transform-origin: 0% 0%;
                font-family: sans-serif;
                padding: 0 10px;
            }

            .title {
                width: 315px;
                padding: 0 15px;
                font-weight: bolder;
                color: rgb(0, 0, 0);
                font-size: 16px;
                padding-top: 20px;

                span {
                    font-size: 14px;
                    font-weight: normal;
                    color: rgba(153, 153, 153, 1);
                }
            }
            .allprice{
                padding-bottom: 4px;
            }
            /*审核结果,服务人员 审批意见*/
            .shenhe,.fuwu,.yijian{
                font-size: 15px;
            }
            .describe {
                padding-bottom: 15px;
                padding-top: 10px;
                color: rgb(82, 82, 82);
                font-weight: normal;
            }

            .info {
                padding: 15px;
                line-height: 15px;

                &.noPadding {
                    padding: 0 15px;
                }

                p {
                    margin: 4px 0;
                    font-weight: bolder;

                    span {
                        font-size: 13px;
                        font-weight: normal;
                        color: rgb(110, 110, 110);
                    }
                }

                .floatR {
                    float: right;
                }

                .total_color {
                    color: rgba(255, 93, 59, 1);
                }
            }

            .text_body {
                padding: 15px;

                .mes_body {
                    border-radius: @redius;
                    background-color: rgb(250, 250, 250);
                }
            }
        }


        .radio_body {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;

            .dot {
                display: inline-block;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: rgb(136, 136, 136);
                vertical-align: middle;
                margin-right: 10px;
                margin-bottom: 6px;

                &.is_dot {
                    background-color: rgb(251, 91, 64);
                }
            }

            .line {
                width: 2px;
                height: 12px;
                background-color: rgb(250, 250, 250);
            }

            .choose {
                margin-right: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;

                .choose_btn {
                    padding: 5px;
                    margin: 10px;
                    color: rgb(136, 136, 136);
                    cursor: pointer;

                    &.pass {
                        color: rgb(251, 91, 64);
                        font-weight: bolder;
                    }

                    &.reject {
                        color: red;
                        font-weight: bolder;
                    }
                }
            }
        }


        .t_table {
            .t_header {
                text-align: center;
                color: rgb(136, 136, 136);
                /*font-weight: bolder;*/
                font-size: 10px;
                height: 40px;
                line-height: 40px;
            }

            .t_title {
                background-color: rgb(244, 249, 255);
                color:#33ADFF;
                text-align: center;
                font-weight: 700;
                font-size: 12px;
                height: 20px;
            }

            .t_td {
                text-align: center;
                height: 40px;
                line-height: 40px;
                font-size: 13px;
            }
        }

        .displayBlock {
            //position: relative;
            overflow: hidden;
            background-color: white;
            border-radius: 9px;
            width:9rem;
            height: auto;
            margin: 19px auto;
        }
        .swiper_show {
            width: 325px;
            position: relative;
            margin: 15px 10px;
            height: 75px;

            .content {
                position: absolute;
                display: flex;
                justify-content: space-around;
                left: 0px;

                .imgWrap {
                    margin-right: 10px;
                }
            }

            .num {
                position: absolute;
                left: 4px;
                top: 6px;
                font-size: 10px;
                background-color: rgb(0, 0, 0);
                opacity: .5;
                color: rgb(255, 255, 255);
                padding: 2px 6px;
                display: inline-block;
                border-radius: 10px;
                font-weight: bolder;
                line-height: 10px;
            }

            /*设计图片部分的css*/
            .part {
                background-color: white;
                border-radius: 8px;
                margin: 15px;

            }


            /*css*/
            .part-subtitle {
                color: #ff5d3b;
                font-size: 12px;
                font-weight: 600;
                position: relative;
                top: 12px;
            }

            /*css*/
            .part-inputpart {
                display: flex;
                flex-direction: column;
                white-space: nowrap;
                overflow: hidden;
                font-size: 15px;
            }


            /*css*/
            .part-inputpart-textarea {
                display: flex;
                flex-direction: column;
                margin-top: 15px;
                background-color: white;
                padding-top: 6px;
                border-radius: 8px;
                z-index: 10;
            }


            /*css*/
            .part-uploadpic-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }

            /*css*/
            .part-uploadpic-module {
                margin: 10px 25px 10px 10px;
            }

            /*css*/
            .part-uploadpic-text {
                font-size: 15px;
                margin-left: 24px;
            }

            /*css*/
            .part-uploadpic-module-pic {
                width: 80px;
                height: 80px;
                border-radius: 8px;
            }

            /*css*/
            .part-uploadpic-van-uploader {
                margin-top: 10px;
                margin-bottom: 10px;
                margin-left: 10px;
            }


            // 图片墙
            .swiper_show01{//参数
                width: 9rem;
                position: relative;
                margin: 10px 10px;
                height: 78px;
                .content01{
                    position: absolute;
                    display: flex;
                    justify-content: space-around;
                    left: 0px;
                    .imgWrap01{
                        margin-right: 10px;
                    }
                }
                .num01{
                    position: absolute;
                    left: 8px;
                    top: 15px;
                    font-size: 10px;
                    background-color: rgb(0,0,0);
                    opacity: .2;
                    color: rgb(255,255,255);
                    padding:2px 6px;
                    display: inline-block;
                    border-radius: 10px;
                    font-weight: bolder;
                    line-height: 10px;
                }
            }
            .displayBlock01 {//参数
                //position: relative;
                overflow: hidden;
                background-color: white;
                border-radius: 9px;
                width:9rem;
                height: auto;
                margin: 19px auto;
            }


            /*tab部分*/
            // 循环表单留着
            .t_table{
                .t_header{
                    text-align: center;
                    color: rgb(136,136,136);
                    font-weight: bolder;
                    font-size: 10px;
                    height: 40px;
                    line-height: 40px;
                }
                .t_title{
                    background-color: rgb(244,249,255);
                    color: rgb(39,135,248);
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                }
                .t_td{
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                }

                /*tab关联部分css*/
                .fuck {
                    margin: 15px;
                }

                /*css*/
                .part-inputpart {
                    display: flex;
                    flex-direction: column;
                    border-radius: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 15px;
                }
                .part-inputpart-dropDownList-option-selected{
                    color: #ff5d3b;
                }


                /*参数*/
                .part-inputpart-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 40px;
                    background-color: #ffffff;
                    margin-top: 2px;
                }
                /*参数*/
                .part-inputpart-row-header{
                    font-weight: 600;
                }



                .part-inputpart-row input {
                    border: 0;
                    width: 100%;
                }


                /*css参数*/
                .part-inputpart-dropDownList{
                    height: 23%;
                    /*垂直方向滚动*/
                    /*overflow-y: scroll;*/
                    overflow: auto;
                    width: 92.5%;
                    position: absolute;
                    z-index: 99;
                }
                /*css参数*/
                .part-inputpart-dropDownList-option{
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    line-height: 30px;
                    background-color: #f5f5f5;
                    font-size: 12px;
                    border-bottom: 1px white solid;
                    color: #999999;
                }


                /*参数*/
                .part-inputpart-row-enablenon {
                    margin-left: 26px;
                }
                /*参数*/
                .part-inputpart-row-graytext {
                    color: #d8d8d8;
                    width: 100%;
                }

                .part-inputpart-row-normaltext{
                    color: black;
                }

                .part-inputpart-row-redtext{
                    width: 100%;
                    color: #ff5d3b;
                }


                /*参数*/
                .part-inputpart-row-right{
                    float: right;
                    margin-right: 10px;
                }

                .part-inputpart-row-right-rightArrow{
                    width: 8px;
                }

                .part-inputpart-row-right-downArrow{
                    width: 9px;
                }

                .part-inputpart-row-right-upArrow{
                    width: 9px;
                    transform: rotateX(180deg);
                }
                .part-inputpart-row-right-timepick{
                    width: 20px;
                }




                .content-have {
                    padding: 3px;
                    margin: 0 10px;
                    border-radius: 50%;
                    background-color: #ff5d3b;
                }

                .content-none {
                    padding: 3px;
                    margin: 0 10px;
                    border-radius: 50%;
                    background-color: #c7c7c7;
                }
                /*参数*/
                .content-divide {
                    font-size: 12px;
                    color: #efefef;
                    margin: 0 10px;
                }

            }

        }
    }

    #manage{
        position: relative;
        left: 1px;
    }
    .span1{
        color: black;
        font-weight: bold;
    }
    .orderSource1{
        position: relative;
        top: 21px;
        left: -49px;
        width: 300px;
        height: 50px;
    }
    #brandId{
        position: relative;
        left: -25px;
    }
    #modelId{
        position: relative;
        left: -25px;
    }
    .dispatchText{
        width: 1.70667rem;
        height: 0.64rem;
        font-size: 0.32rem;
        color: #e96b48;
        font-weight: bold;
        margin-left: 0.5rem;
        margin-top: 28px;
    }
    #MaintenanceLevel{
        color: coral;
        position: relative;
        top: -1px;
    }
    #matainDetils{
        width: 288px;
        line-height: 22px;
    }
    #appointment{
        width: 130px;
        position: relative;
        top: 1px;
        color: coral;
        left: 1px;
    }
    #maintainDegree{
        position: relative;
        top: 23px;
    }
    #contentId{
        position: relative;
        left: -10px;
    }
    #MaintenanceLevel1{
        color: coral;
        position: relative;
        top: -1px;
    }
    #matainDetils1{
        width: 210px;
        margin-left: 90px;
    }
    #appointment1{
        width: 152px;
        position: relative;
        top: 1px;
        color: coral;
    }
    #maintainDegree1{
        position: relative;
        top: 23px;
    }
    #contentId1{
        position: relative;
        left: -10px;

    }
    .navbar{
        z-index: 10;
        background-color: #f8f8f8;
    }
    .broadBandDetails{
        height: auto;
    }
    .broadBandDetails-Apply{

        height: 215px;
        background-color: white;
        margin-left: 16px;
        width: 91%;
        position: relative;top:2px;
        border-radius: 8px;
    }
    .broadBandDetails-dispatch{

        height: 167px;
        background-color: white;
        margin-left: 16px;
        width: 91%;
        margin-top: 1px;
        border-radius: 8px;
    }
    .applyText{
        width: 1.70667rem;
        height: 0.64rem;
        margin-top: 45px;
        margin-left: 0.5rem;
        position: relative;
        top: 18px;
        font-size: 0.32rem;
        color: #e96b48;
        font-weight: bold;
    }
    .address{
        height: 46px;
        width: 100%;
        font-size: 12px;
        border-bottom: 2px solid #f8f8f8;
        line-height: 46px;
    }
    .address1{
        height: 2px;
        width: 100%;
    }
    .addressH3{
        padding-left: 13px;
        font-weight: 500;
    }
    .repairee{
        background-color: white;
        height: 20px;
        width: 100px;
        margin-top: 14px;
        font-size:12px;
        margin-left: 14px;
    }
    .title{
        height: 20px;
        width: auto;
        font-weight: bold;
        font-size: small;
    }
    .content{
        float: left;
        background-color: white;
        margin-top: -19px;
        margin-left: 63px;
        font-size: small;
        float: left;
        color: grey;
    }
    .contactInformation{
        background-color: white;
        height: 20px;
        width: 152px;
        margin-top: -20px;
        float: left;
        margin-left: 165px;
    }
    .orderSource{
        background-color: white;
        height: 20px;
        width: 152px;
        margin-top: 4px;
        margin-left: 14px;
    }


</style>
